import kebabCase from 'lodash/kebabCase';

import theme, { Color, StyleDictionary, TextStyle, ThemeProps } from '../theme';
import styled from 'styled-components';
import colors from '@legacy-components/theme/colors';

interface StyledTextProps extends ThemeProps {
  styleKey: string;
  color?: Color;
}

function computeTextStyle({ theme, color, styleKey }: StyledTextProps): string {
  let textStyle: StyleDictionary = theme.textStyle[styleKey];

  // Default to dark when there is no color defined
  if (!color && !textStyle.color) {
    textStyle = { ...textStyle, color: theme.color.dark };
  }

  // Theme color
  if (!color && textStyle.color instanceof Function) {
    textStyle = { ...textStyle, color: textStyle.color({ theme }) };
  }

  // Override color when provided
  if (color) {
    textStyle = { ...textStyle, color: theme.color[color] };
  }

  return Object.keys(textStyle).reduce(
    (style: string, textStyleProp: string): string => `
      ${style}
      ${kebabCase(textStyleProp)}: ${textStyle[textStyleProp]};
    `,
    '',
  );
}

export const StyledText = styled.div<{ styleKey: TextStyle; color?: Color }>`
  ${({styleKey, color}) => computeTextStyle({theme: theme, styleKey, color})}
`;

export interface LoadableTextProps extends ThemeProps, StyledTextProps {
  isLoading: boolean;
}

export const LoadableText = styled.div`
  background-color: ${colors[Color.GRAY_300]};
  color: ${colors[Color.GRAY_300]};
  height: 20px;
  width: max-content;
`;
