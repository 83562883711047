import React from 'react';
import { Circle, Label } from './styledComponents';
type Props = {
  counter: number;
};
const Badge = ({
  counter
}: Props) => <Circle data-sentry-element="Circle" data-sentry-component="Badge" data-sentry-source-file="index.tsx">
    <Label data-sentry-element="Label" data-sentry-source-file="index.tsx">{counter}</Label>
  </Circle>;
export default Badge;