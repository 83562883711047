import styled from 'styled-components';

import Text from '../Text';
import { Color, TextStyle, em } from '../theme';
import {
  Size,
  borderFromTheme,
  colorFromTheme
} from '../theme/helper';

const EM_SIDE_SIZE = 1;

export const Circle = styled.div`
  width: ${em(EM_SIDE_SIZE)};
  height: ${em(EM_SIDE_SIZE)};
  border-radius: ${em(EM_SIDE_SIZE / 2)};
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${colorFromTheme(Color.DANGER)};
  border: ${borderFromTheme(Size.MEDIUM)} solid ${colorFromTheme(Color.LIGHT)};
`;

export const Label = styled(Text).attrs({
  styleKey: TextStyle.BODY_4,
  color: Color.LIGHT,
})`
  text-align: center;
  vertical-align: middle;
`;
